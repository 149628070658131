import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/company/interpretation-case/list',
    method: 'get',
    params
  })
}


export function createAPI(data) {
  return http({
    url: '/company/interpretation-case/add',
    method: 'post',
    data
  })
}


export function editAPI(data) {
  return http({
    url: '/company/interpretation-case/edit',
    method: 'post',
    data
  })
}


export function delAPI(id) {
  return http({
    url: '/company/interpretation-case/del',
    method: 'post',
    data: {id}
  })
}

export function detailAPI(id) {
  return http({
    url: '/company/interpretation-case/detail',
    method: 'get',
    params: {id}
  })
}


export function modifyIsPublishAPI(data) {
  return http({
    url: '/company/interpretation-case/modifyIsPublish',
    method: 'post',
    data
  })
}
